<template>
  <section>
    <section class="ConOfproductsHead">
      <div class="stoppingHead"></div>
      <div class="conOfprodHeadTxt">
        <h1>Living with Pain</h1>
        <div class="mt-2">
          Living with pain makes day-to-day life difficult. It touches every
          single part of your life, from productivity, to relationships, to
          sleeping.
        </div>
        <div class="mt-2">
          You never know how much pain you’ll wake up with or how it will change
          throughout the day.
        </div>
        <div class="mt-2">
          Waiting for analgesics to work brings additional pain and anxiety.
        </div>
      </div>
    </section>

    <section class="stopping-sec mt-5">
      <v-container class="h-100">
        <div class="conOfstopSec">
          <v-row class="h-100 py-5">
            <v-col md="4" cols="12">
              <div class="conOfheadTxtRev">
                <div class="titleTxtpurple my-2">What’s stopping you?</div>

                <div class="conOfline d-flex jusify-center">
                  <span class="smallthinLine"></span>

                  <span class="thickLine"></span>
                  <span class="thinLine"></span>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="justify-space-around py-5 px-2">
            <v-col md="6" cols="12">
              <div class="stopTxt mb-5">
                The fear of long-term analgesics’ side effects and tolerance
              </div>
              <div class="stopTxt mb-5">
                Treatment complexity and pill burden
              </div>
              <div class="stopTxt mb-5">
                Suffering from other co-morbidities
              </div>
            </v-col>
            <v-col md="5" cols="12">
              <img src="@/assets/img/stop2.svg" class="img-fluid" />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="sideEffects-sec mt-5">
      <v-container class="h-100">
        <div class="conOfsideSec pa-5">
          <v-row class="justify-space-between py-5">
            <v-col md="5" cols="12" class="pa-5 ma-5">
              <p class="uPainTxtHead my-2">
                Due to the serious side effects associated with long term use of
                real analgesics or pain
              </p>
              <div class="uPainsubTxt my-2">
                there has been an increasing focus on development of new routes
                of drug administration to provide tailored treatments for
                patients, without decreasing efficacy of analgesia. Topical
                medications were developed to overcome these challenges and
                relief pain at peripheral sites, with maximum active drug an
                minimal systemic effects. It is evidenced that topically applied
                medications can be almost as effective as those taken orally,
                with a good safety profile in terms of adverse effects.3 The
                ultimate goal that motivates the development of topical
                preparations is the improvement of patient compliance to medical
                treatment, by providing efficient pain relief with less central
                nervous system effects and minimal drug regimen burden.
              </div>
            </v-col>
            <v-col
              md="5"
              cols="12"
              class="d-flex justify-lg-end justify-center"
            >
              <img src="@/assets/img/side1-min.png" class="img-fluid w-75" />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="forget-sec mt-5 mb-5">
      <v-container class="h-100">
        <div class="pa-5">
          <v-row class="justify-space-between py-5">
            <v-col md="5" cols="12" class="pa-3 ma-3">
              <p class="uPainTxtHead my-2">
                Now you can forget your muscle pain
              </p>
              <div class="uPainsubTxt my-2">
                Move is offering you various forms to help relief your muscle
                and joint pain Wherever your muscle pain is, Move can help you
                <div class="mt-4">Why do we use it?</div>
              </div>
              <router-link to="/about" class="readYellow"
                >Explore More <span>&#8594;</span></router-link
              >
            </v-col>
            <v-col md="4" cols="12" class="d-flex justify-end">
              <img src="@/assets/img/forget-2.png" class="img-fluid" />
            </v-col>
          </v-row>
          <!--  <v-row>
            <v-col md="12">
              <VueSlickCarousel :arrows="true" v-bind="settings">
                <div
                  class="ma-4 conOfProduct"
                  :key="index"
                  v-for="(item, index) in slides"
                >
                  <router-link to="/about">
                    <div
                      class="d-flex align-center justify-center conOfslideImg"
                    >
                      <img
                        :src="item.img"
                        :class="!item.cream ? 'slideImg' : ''"
                      />
                    </div>
                    <div class="slideTxt">{{ item.title }}</div>
                  </router-link>
                </div>
              </VueSlickCarousel>
            </v-col>
          </v-row> -->
        </div>
      </v-container>
    </section>
  </section>
</template>
<script>
export default {
  name: "understandingPain",
  components: {},

  data() {
    return {
      CurrentTab: "Sprays",
      settings: {
        dots: false,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      settingsReviews: {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        touchThreshold: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      videoOptions: {
        autoplay: false,
        muted: false,
        controls: true,
        preload: "auto",
        sources: [
          {
            src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
            type: "video/mp4",
          },
        ],
      },
      slides: [
        {
          img: require("@/assets/img/Move on freeze spray-min.png"),
          title: "Move on freeze spray",
          dec: "Freeze spray for athletes, fitness enthusiasts, and sporty people who require cooling effect to relieve muscle spasm/ cramps and aches, easy to carry and apply within seconds , provides a highly pressurized force of cooling menthol that quickly numbs the pain, low temperatures immediately relieve swelling and pain",
        },
        {
          img: require("@/assets/img/Move On Freez Gel-min.png"),
          title: "Move On Freeze Gel",
          cream: true,

          dec: "Gel for rapid relief with cooling effect targeting young people who are exposed to accidental injuries and muscle aches",
        },

        {
          img: require("@/assets/img/Move on Spray-min.png"),
          title: "Move on spray",
          dec: "Spray for easy application for those who do not tolerate or do not like rubbing cream, it also provides instant relief, Sprays can be excellent for hard-to-reach spots like your back.",
        },
        {
          img: require("@/assets/img/Move On Cream-min.png"),
          cream: true,
          title: "Move On Cream",
          dec: " Cream is for elderlies suffering from chronic pain and muscle ache ",
        },
        {
          img: require("@/assets/img/Move On Hot Patch-min.png"),
          title: "Move On Hot Patch",
          dec: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
        },
        {
          img: require("@/assets/img/Move On Freeze Patch-min.png"),
          title: "Move On Freeze Patch",
          dec: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
        },
      ],
    };
  },

  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
@import "./stopping";
</style>
